import moment from 'moment';
import { createAuthorizedApiEpic, sortPageFilterQuery } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';

export const createReviewRequest = createAuthorizedApiEpic(
	'game/create_review_request',
	(callApi, { gameId, versionId, data: { developerNotes } }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/reviews`),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'reviews',
				developer_notes: developerNotes,
			},
		})),
	}),
);

export const cancelReviewRequest = createAuthorizedApiEpic(
	'game/cancel_review_request',
	(callApi, { gameId, versionId, reviewId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/reviews/${reviewId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'reviews',
				status: 'closed',
			},
		})),
	}),
);

export const getReviewsForGame = createAuthorizedApiEpic(
	'game/get_reviews_for_game',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/reviews`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getAllPendingReviews = createAuthorizedApiEpic(
	'reviews/get_all_pending_reviews',
	(callApi, { sortField = 'queue_time', sortDirection = 1 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page: 1, perPage: 9999999999, filters: { status: 'pending' } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getPendingReviews = createAuthorizedApiEpic(
	'reviews/get_pending_reviews',
	(callApi, { sortField = 'queue_time', sortDirection = 1, page = 1, perPage = 1000 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters: { status: 'pending' } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getCompletedReviews = createAuthorizedApiEpic(
	'reviews/get_completed_reviews',
	(callApi, { sortField = 'report_submitted_at', sortDirection = -1, page = 1, perPage = 1000 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters: { status: ['rejected', 'approved'] } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getAllReviewsForToday = createAuthorizedApiEpic(
	'reviews/get_all_reviews_today',
	(callApi, { sortField = 'queue_time', sortDirection = 1 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page: 1, perPage: 999999999999, filters: { created_at: `between:${moment().format('YYYY-MM-DD')}:${moment().add(1, 'day').format('YYYY-MM-DD')}` } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getAllReportsForToday = createAuthorizedApiEpic(
	'reviews/get_all_reports_today',
	(callApi, { sortField = 'queue_time', sortDirection = 1 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page: 1, perPage: 999999999999, filters: { report_submitted_at: `between:${moment().format('YYYY-MM-DD')}:${moment().add(1, 'day').format('YYYY-MM-DD')}` } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const getAllReportsForYesterday = createAuthorizedApiEpic(
	'reviews/get_all_reports_yesterday',
	(callApi, { sortField = 'queue_time', sortDirection = 1 }) => callApi({
		url: getApiUrl('devs', `reviews?${sortPageFilterQuery({ sortField, sortDirection, page: 1, perPage: 999999999999, filters: { report_submitted_at: `between:${moment().subtract(1, 'day').format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}` } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const sendReviewReport = createAuthorizedApiEpic(
	'reviews/send_review_report',
	(callApi, { gameId, versionId, reviewId, data: { status, personalMessage, reportUrl } }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/reviews/${reviewId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'reviews',
				status,
				personal_message: personalMessage,
				report_url: reportUrl,
			},
		})),
	}),
);

export const setReviewReportPrivateNotes = createAuthorizedApiEpic(
	'reviews/set_private_notes',
	(callApi, { gameId, versionId, reviewId, data: { pokiPrivateNotes } }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/reviews/${reviewId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'reviews',
				poki_private_notes: pokiPrivateNotes,
			},
		})),
	}),
);

export const getReviewById = createAuthorizedApiEpic(
	'reviews/get_review_by_id',
	(callApi, { gameId, versionId, reviewId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/reviews/${reviewId}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);
