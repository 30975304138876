import React from 'react';
import { Helmet } from 'react-helmet';

import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';

import getCDNImage from 'site/src/utils/getCDNImage';

import { ParentDirectory, CalloutRow, CallOutColumn, Main, Navigation, Title, CalloutTitle, ContentParagraph, StyledLink, SubTitle } from 'site/src/components/guide.styled';
import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import MarketingFooter from 'shared/components/MarketingFooter';
import SignUpCTA from 'site/src/components/SignUpCTA';

const GuideEngagementPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Engagement</Title>
				<ContentParagraph extraLarge>
					There’s no one size fits all solution to increasing your engagement, so here’s a few ideas you could try to keep players hooked!
				</ContentParagraph>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>🎉 Congratulate the player</CalloutTitle>
						<ContentParagraph large>
							At the end of a level or whenever the player reaches a milestone - it’s both nice and important to give the player a little pat on the back. Some confetti, a nice sound effect and a some words of praise helps a lot to boost the player’s confidence and keep them going!
						</ContentParagraph>
						<ContentParagraph>
							<StyledLink href="https://poki.com/en/g/puffy-cat" target="_blank">Puffy Cat</StyledLink>’s victory screen has all the hallmarks of a good player celebration and adds on a happy cat for good measure.
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img alt="loading example" src={getCDNImage({ src: '/images/guide/engagement-congratulate.png', width: 800 })} />
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>🥅 Clear goals</CalloutTitle>
						<ContentParagraph>
							Finishing a level is a short-term goal, but try to implement long-term goals as well. If players have a goal like a new item, weapon or power-up to work towards, they are more likely to keep playing in order to obtain that reward!
						</ContentParagraph>
						<ContentParagraph>
							<StyledLink href="https://poki.com/en/g/stickman-hook" target="_blank">Stickman Hook</StyledLink> has the player progress towards unlocking a new character at the end of every level and is a good example of a long-term goal!
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img alt="loading example" src={getCDNImage({ src: '/images/guide/engagement-reward.png', width: 800 })} />
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle> 🎛️ Tune the difficulty</CalloutTitle>
						<ContentParagraph>
							Balancing the difficulty of your game is a tricky task. You don’t want to overwhelm players at the start of your game by making the game too difficult, but you also don’t want the game to be too easy. Try to create an increasing scale of difficulty in your game and find a middle ground - starting off easy and getting more difficult as you continue.
						</ContentParagraph>
						<ContentParagraph>
							Although it is a difficult game, <StyledLink href="https://poki.com/en/g/level-devil" target="_blank">Level Devil</StyledLink> cales the difficulty bit by bit as the player progresses by introducing new mechanics. Have a look at the game as an interesting example of game with well-tuned difficulty.
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img alt="loading example" src={getCDNImage({ src: '/images/guide/engagement-difficulty.png', width: 800 })} />
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle> ⌨️ Add touch and keyboard controls</CalloutTitle>
						<ContentParagraph>
							Players should be able to navigate in the game using both mouse and keyboard controls. WASD or Arrow keys should navigate in the menu. Space bar or Return should activate the primary button.
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img alt="loading example" src={getCDNImage({ src: '/images/guide/engagement-controls.png', width: 800 })} />
					</CallOutColumn>
				</CalloutRow>

				<SubTitle>📝 Test it!</SubTitle>
				<ContentParagraph large>
					The best way to see if your players respond well to the messages and rewards, as well as to see wether your tweaks and improvements to the difficulty are working is by using playtesting! Try to find out where players stop playing, and what might be causing them to drop off. Are they stuck? That might be a difficulty issue. Do they just seem to lose interest? Then you might need to introduce a reward or give the player a challenge!
				</ContentParagraph>
			</Main>
			<Navigation
				nextLink="/guide/localization"
				nextLabel="Localization"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideEngagementPage;
