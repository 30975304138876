import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';

import getCDNImage from 'site/src/utils/getCDNImage';

import { ParentDirectory, Callout, CalloutRow, CallOutColumn, Main, Navigation, Title, CalloutTitle, IframeContainer, StyledIframe, SubTitle, StyledLink, ContentParagraph } from 'site/src/components/guide.styled';
import { Container, BackgroundImage, Page } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import SignUpCTA from 'site/src/components/SignUpCTA';
import MarketingFooter from 'shared/components/MarketingFooter';

const StyledList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`;

const GuideEasyAccessPage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game guide</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_heart.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Easy access</Title>
				<ContentParagraph extraLarge>
					You never get a second chance to make a good first impression, and the same can be said about your games. The first couple of minutes can be the determining factor for your game’s success. That’s why the onboarding of your game is a very important part to focus on. And we have some tips to help you.
				</ContentParagraph>
				<SubTitle>File size and loading</SubTitle>
				<ContentParagraph large>
					Let’s start with the technical stuff. Making sure your game loads fast and runs well is the first step towards an easily accessible game. Below, you’ll find some best practices for games on Poki.
				</ContentParagraph>
				<Callout>
					<CallOutColumn>
						<CalloutTitle>🤏 Keep it small</CalloutTitle>
						<ContentParagraph large>
							Web games are playable on all types of devices. This is great, but also means that some players might be playing on older devices or slower internet connections than others. To make sure all players can play your game - try to keep your game’s file size as small as possible. Around 8 to 10MB is the size to aim for!
						</ContentParagraph>
						<ContentParagraph large>
							For tips or guides on file compression and size reduction for your engine of choice, have a look at these links:
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<StyledList large>
							<li><StyledLink href="https://sdk.poki.com/unity.html#optimizing-your-unity-build" target="_blank">Unity</StyledLink></li>
							<li><StyledLink href="https://docs.godotengine.org/en/stable/contributing/development/compiling/optimizing_for_size.html" target="_blank">Godot</StyledLink></li>
							<li><StyledLink href="https://www.construct.net/en/make-games/manuals/construct-3/tips-and-guides/performance-tips" target="_blank">Construct 3</StyledLink></li>
							<li><StyledLink href="https://defold.com/manuals/optimization/" target="_blank">Defold</StyledLink></li>
							<li><StyledLink href="https://gamemaker.io/en/tutorials/how-to-optimise-your-games" target="_blank">GameMaker</StyledLink></li>
							<li><StyledLink href="https://pixijs.com/8.x/guides/production/performance-tips" target="_blank">PixiJS</StyledLink></li>
							<li><StyledLink href="https://developer.playcanvas.com/user-manual/optimization/" target="_blank">PlayCanvas</StyledLink></li>
							<li><StyledLink href="https://www.stencyl.com/help/view/optimize-game-performance/" target="_blank">Stencyl</StyledLink></li>
							<li><StyledLink href="https://www.threejsdevelopers.com/blogs/optimizing-three-js-performance-for-smooth-rendering/" target="_blank">ThreeJS</StyledLink></li>
						</StyledList>
					</CallOutColumn>
				</Callout>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>⏳ Loading screens</CalloutTitle>
						<ContentParagraph large>
							A loading bar is the perfect way to give the player a sense of progression while the game is getting ready. Especially visually engaging loading screen that feature the logo or some visuals of your game will help prevent any early drop-offs by players mistaking the game as frozen or broken.
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img
							src={getCDNImage({ src: '/images/guide/loading-example.png', width: 800 })}
							alt="loading example"
						/>
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>🏗️ Progressive loading</CalloutTitle>
						<ContentParagraph large>
							Another way to speed up the loading times of your game is through progressive loading. This is a technique where only the most necessary files (main menu, tutorial level, first stage, etc.) are downloaded when the game starts. While the player is in this part of the game, the game downloads the remaining files in the background.
							This may sound like a gimmick, but we promise it’s<strong>one of the most important topics for success on the web</strong>. The amount of players who drop off during loading shouldn’t be underestimated. Watch our colleague Kasper Mol’s presentation on this topic.
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<IframeContainer>
							<StyledIframe src="https://www.youtube.com/embed/1odNKssL3Oc" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
						</IframeContainer>
					</CallOutColumn>
				</CalloutRow>
				<SubTitle>Onboarding</SubTitle>
				<ContentParagraph large>
					Now that we’ve got our loading and technical set-up all optimised, let’s have a look at the game! Below, we’ll share some tips about how to start your game off right:
				</ContentParagraph>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>💨 Skip the menu</CalloutTitle>
						<ContentParagraph large>
							Make sure your game starts up fast. The faster that players get to experience all the cool things your game has to offer, the better! For first-time players, it’s especially important to skip splash screens, title screens, and level selects. Let them jump right into the good part!
						</ContentParagraph>
						<ContentParagraph large>
							<StyledLink href="https://poki.com/en/g/monkey-mart" target="_blank">Monkey Mart</StyledLink> is a great example of a fast onboarding approach!
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img
							src={getCDNImage({ src: '/images/guide/ea-skip.png', width: 800 })}
							alt="screenshot of Monkey Mart"
						/>
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>🦺 A safe beginner environment</CalloutTitle>
						<ContentParagraph large>
							Whenever you begin playing a new game - it can be difficult to get started. So it’s nice to give the player an easy time learning what your game is all about! Start off with some simpler levels that players will easily finish and scale the difficulty from there. That way, players will get the feeling they’re able to win, which will help them stay engaged with your game.
						</ContentParagraph>
						<ContentParagraph large>
							In <StyledLink href="https://poki.com/en/g/subway-surfers" target="_blank">Subway Surfers</StyledLink>, players can’t die while they’re moving through the onboarding. Instead, they get the chance to go back and try again until they get it right!
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img
							src={getCDNImage({ src: '/images/guide/ea-environment.png', width: 800 })}
							alt="screenshot of Subway Surfers"
						/>
					</CallOutColumn>
				</CalloutRow>
				<SubTitle>Tutorials</SubTitle>
				<ContentParagraph large>
					Now that the player is in the game, we have to show them the ropes and help them get the hang of things. A good tutorial explains to the player how the mechanics work in a way that feels natural, teaching them what the game is about without blocking the gameplay. Here are some tips to make your tutorial helpful and snappy!
				</ContentParagraph>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>👁️ Explain the game with visuals</CalloutTitle>
						<ContentParagraph large>
							Text can be very useful to explain something, but it can also complicate things. For example, large amounts of text can be intimidating to players. Texts in English might also be hard to understand for non-English speaking players. If you’re explaining something in your game, try using images, animations or gestures to point things out. As they say, an image says more than a thousand words!
						</ContentParagraph>
						<ContentParagraph large>
							<StyledLink href="https://poki.com/en/g/blumgi-slime" target="_blank">Blumgi Slime</StyledLink> has a great example of a visual tutorial!
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img
							src={getCDNImage({ src: '/images/guide/ea-explain.png', width: 800 })}
							alt="screenshot of Blumgi Slime"
						/>
					</CallOutColumn>
				</CalloutRow>
				<CalloutRow>
					<CallOutColumn>
						<CalloutTitle>📈 Gradual introduction</CalloutTitle>
						<ContentParagraph large>
							Gradually showing players the actions or buttons they will need over the course of a couple of levels can  also help ease players into a game. This reduces ‘frontal load’ which is where players are shown everything they could possibly need to know about a game straight away. This often makes players feel overwhelmed and leads to them ending their play session.
						</ContentParagraph>
						<ContentParagraph large>
							<StyledLink href="https://poki.com/en/g/heart-star" target="_blank">Heart Star</StyledLink> is a good example of how a gradual introduction can work!
						</ContentParagraph>
					</CallOutColumn>
					<CallOutColumn>
						<img
							src={getCDNImage({ src: '/images/guide/ea-introduction.png', width: 800 })}
							alt="screenshot of Heart Star"
						/>
					</CallOutColumn>
				</CalloutRow>
				<SubTitle>📝 Test it!</SubTitle>
				<ContentParagraph large>
					With these tips in mind, it’s time to playtest your new onboarding! Poki Playtest recordings let you see exactly how players are moving through the early parts of your game. By watching how they play, you can fine-tune the onboarding to ensure the game kicks off smooth and fast!
				</ContentParagraph>
			</Main>
			<Navigation
				nextLink="/guide/engagement"
				nextLabel="Engagement"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideEasyAccessPage;
