const isDevelopment = process.env.NODE_ENV === 'development';

export default function getCDNImage({ src, width, height }) {
	if (isDevelopment) {
		return `${src}?w=${width}&h=${height}`;
	}

	if (width && !height) {
		return `/.netlify/images/?url=${src}&w=${width}&fm=webp&q=75`;
	}

	return `/.netlify/images/?url=${src}&w=${width}&h=${height}&fm=webp&q=75`;
}
